import React from "react"
import Styled from "./styled.module.css"

function Hero() {
  return (
    <section className={Styled.section}>
      <div className="container">
        <div className={Styled.addressWrapper}>
          <div className={Styled.address}>
            <p className="sm-text bold">US Headquarters</p>
            <p className={`${Styled.addressTitle} sm-text`}>
              Integra Global Solutions Corp
            </p>
            <p className="md-text">
              7500 Brooktree Road,
              <br />
              Suite 100, Wexford (Pittsburgh)
              <br />
              PA 15090
            </p>
            <a
              href="tel:+ 1 412 267 1529"
              className={`${Styled.phone} sm-text`}
            >
              + 1 412 267 1529
            </a>
          </div>
          <div className={Styled.address}>
            <p className="sm-text bold">United Kingdom</p>
            <p className={`${Styled.addressTitle} sm-text`}>
              Integra Global Solutions UK LTD
            </p>
            <p className="md-text">
              College House <br /> 17 King Edwards Road <br /> Ruislip, London,
              UK <br />
              HA4 7AE
            </p>
            <a
              href="tel:+ 44 020 7993 2949"
              className={`${Styled.phone} sm-text`}
            >
              + 44 020 7993 2949
            </a>
          </div>
          <div className={Styled.address}>
            <p className="sm-text bold">Canada</p>
            <p className={`${Styled.addressTitle} sm-text`}>
              Integra Global Solutions Corp
            </p>
            <p className="md-text">
              32 Blencathra Hill,
              <br /> Unit 100,
              <br /> Markham
              <br /> Ontario
            </p>
            <a href="tel:+1 412 267 1529" className={`${Styled.phone} sm-text`}>
              +1 412 267 1529
            </a>
          </div>
          <div className={Styled.address}>
            <p className="sm-text bold">Philippines</p>
            <p className={`${Styled.addressTitle} sm-text`}>
              Integra Global Solutions Corp
            </p>
            <p className="md-text">
              14th Floor Ayala Center Cebu Tower Bohol Street, Cebu Business
              Park Cebu City, Cebu province, 6000 Philippines
            </p>
            <a href="tel:+1 412-267-1529" className={`${Styled.phone} sm-text`}>
              +1 412-267-1529
            </a>
          </div>
          <div className={Styled.address}>
            <p className="sm-text bold">India</p>
            <p className={`${Styled.addressTitle} sm-text`}>
              Integra Global Solutions Corp
            </p>
            <p className="md-text">
              First floor, Kanapathy Towers,
              <br /> Opp. BSNL exchange
              <br /> Ganapathy
              <br />
              Coimbatore - 641 006
            </p>
            <a
              href="tel:+91 0422 432 8555"
              className={`${Styled.phone} sm-text`}
            >
              +91 0422 432 8555
            </a>
          </div>
          <div className={Styled.address}>
            <p className="sm-text bold">India</p>
            <p className={`${Styled.addressTitle} sm-text`}>
              Integra Global Solutions Corp
            </p>
            <p className="md-text">
              No.1, Palsun Towers, 1st <br /> Street, Behind of KVB Bank <br />{" "}
              Tatabad, Sivananda Colony, <br /> Coimbatore - 641012
            </p>
            <a
              href="tel:+91 0422 432 8555"
              className={`${Styled.phone} sm-text`}
            >
              +91 0422 432 8555
            </a>
          </div>

          <div className={Styled.address}>
            <p className="sm-text bold">Australia</p>
            {/* <p className={`${Styled.addressTitle} sm-text`}>
              Integra Global Solutions Corp
            </p>
            <p className="md-text">
              No.1, Palsun Towers, 1st Street, Behind of KVB Bank Tatabad,
              Sivananda Colony, Coimbatore - 641012
            </p> */}
            <a
              href="tel:+61 02 8005 1836"
              className={`${Styled.phone} sm-text`}
            >
              +61 02 8005 1836
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
