import React from "react"
import Illustrator from "../../../assets/img/illustration-4.svg"
import FacebookIcon from "../../../assets/img/social/facebook-primary.svg"
import TwitterIcon from "../../../assets/img/social/twitter-primary.svg"
import YoutubeIcon from "../../../assets/img/social/youtube-primary.svg"
import LinkedinIcon from "../../../assets/img/social/linkedin-primary.svg"
import Styled from "./styled.module.css"

function Connect() {
  return (
    <section className={Styled.section}>
      <div className="container">
        <div className={Styled.wrapper}>
          <img className={Styled.img} alt="Illustrator" src={Illustrator}></img>
          <div className={Styled.connectWrapper}>
            <h1 className="sm-title bold">
              Let’s <br />
              connect.
            </h1>
            <div className={Styled.socialWrapper}>
              <a
                target="_blank"
                href="https://www.facebook.com/IntegraGlobalSolutions"
              >
                <img alt="Facebook Logo" src={FacebookIcon}></img>
              </a>

              <a target="_blank" href="https://twitter.com/integra_tweet">
                <img alt="Twitter Logo" src={TwitterIcon}></img>
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/user/IntegraGlobalSolns?feature=mhee"
              >
                <img alt="Youtube Logo" src={YoutubeIcon}></img>
              </a>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/integra-global-solutions-corp"
              >
                <img alt="linkedIn Logo" src={LinkedinIcon}></img>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Connect
