import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout/"
import Hero from "../components/ContactPage/Hero"
import Contact from "../components/ContactPage/Contact"
import Connect from "../components/ContactPage/Connect"
import "../assets/css/normalize.css"
import "../assets/css/custom.css"
import FeaturedImage from "../assets/img/integra-featured-image.png"

export default function Home() {
  return (
    <>
      <Helmet
        title="Contact us for business services"
        meta={[
          {
            name: `description`,
            content:
              "Do you need help to grow your business? Contact us to find out about our business services and how we can help you achieve your goals",
          },
          {
            name: "keywords",
            content:
              "contact us, quote, offices, contact now, integra contact information, contact information",
          },
        ]}
      >
        <meta property="og:url" content="https://www.globalintegra.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Contact us for business services" />
        <meta
          property="og:description"
          content="Do you need help to grow your business? Contact us to find out about our business services and how we can help you achieve your goals"
        />
        <meta property="og:image" content={FeaturedImage} />

        <meta name="twitter:card" content="summary" />
        <meta property="twitter:domain" content="globalintegra.com" />
        <meta property="twitter:url" content="https://www.globalintegra.com/" />
        <meta name="twitter:title" content="Contact us for business services" />
        <meta
          name="twitter:description"
          content="Do you need help to grow your business? Contact us to find out about our business services and how we can help you achieve your goals"
        />
        <meta name="twitter:image" content={FeaturedImage}></meta>
      </Helmet>
      <Layout>
        <main>
          <Hero />
          <Contact />
          <Connect />
        </main>
      </Layout>
    </>
  )
}
