import React from "react"

import ContactForm from "../Form"
import Styled from "./styled.module.css"

export default function Hero() {
  return (
    <section className={Styled.section}>
      <div className={`${Styled.heroWrapper} container`}>
        <div className={Styled.heroDescription}>
          <div className={Styled.titleWrapper}>
            <h1 className={`${Styled.heroTitle} lg-title bold`}>
              Choose flexible terms & pricing
            </h1>
            <p className={`${Styled.heroSubtitle} lg-text`}>
              We have been helping companies of all sizes.
              <br /> Join our list of happy clients{" "}
              <span className="bold">today</span>.
            </p>
          </div>
        </div>
        <ContactForm></ContactForm>
      </div>
    </section>
  )
}
